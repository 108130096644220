/* MusicReleases.css */
.music-releases {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 300px; /* Added padding-bottom */

}


.embed {
    width: 85%; /* Adjusted width to 85% */
    padding-bottom: 10px;
    height: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: all 1.5s ease-in-out;
    will-change: opacity, transform;
}

@media (min-width: 768px) {
    .embed {
        width: 45%; /* Adjusted width to 45% for larger screens */
    }

    .embed-left {
        margin-right: auto;
    }

    .embed-right {
        margin-left: auto;
    }
}

/* Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.is-visible {
    animation: fadeInUp 1s ease forwards;
}

/* MusicReleases.css */
.hide-bandcamp {
    display: none;
}
