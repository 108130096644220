.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}

.navbar.show {
  opacity: 1;
  transform: translateY(0);
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin: 0;
  padding: 0;
}

.navbar-link,
.navbar-link-button {
  color: #fcfcfc;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none; /* Remove drop shadow */
}

.navbar-link:hover,
.navbar-link-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.active {
  border-bottom: 2px solid #ffffff;
}
