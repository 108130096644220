/* MasteringPage.css */

.mastering-section {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.white-background {
  background-color: #fff;
}

@keyframes rainbow {
  100% {
    background-color: violet;
    opacity: 1;
  }
}

.card {
  background-color: #fff;
  padding: 30px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
}

.card:hover,
.card:focus {
  transform: translateY(-10px);
  box-shadow: none;
}

.mastering-title,
h3,
p {
  text-align: center;
  margin-bottom: 16px;
}

.mastering-title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 16px;
}

h3 {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

p {
  color: #888;
  font-size: 0.9rem;
  line-height: 1.4;
}

button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: rgba(58, 58, 58, 0.777);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgb(222, 222, 222);
  border-radius: 5px;
}

button:hover {
  background-color: #3367d6;
  animation: rainbow 5s infinite;
  box-shadow: none;
}

.additional-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .card {
    padding: 20px;
    margin: 10px;
    box-shadow: none; /* Remove drop shadow on mobile */
  }

  .mastering-title {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }

  button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
