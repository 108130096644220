/* PasswordPage.css */

.password-page {
  background-color: #efe7e4; /* Match this color to your desired background */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.wave-container {
  position: relative;
  width: 400px; /* Adjusted width to contain images closer together */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave-image {
  position: relative;
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  opacity: 0;
  /* Remove any borders or outlines */
  border: none;
  outline: none;
  /* Ensure background is transparent */
  background-color: transparent;
  padding: 0;
  margin: 0 5px; /* Small margin to bring images closer */
}

.image1 {
  animation: waveAnimation 3s ease-in-out forwards;
}

.image2 {
  animation: waveAnimation 3s ease-in-out forwards 0.3s;
}

.image3 {
  animation: waveAnimation 3s ease-in-out forwards 0.6s;
}

.image4 {
  animation: waveAnimation 3s ease-in-out forwards 0.9s;
}

@keyframes waveAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .wave-container {
    flex-direction: column;
  }

  .wave-image {
    margin: 5px 0; /* Adjust margin for vertical layout */
  }
}
