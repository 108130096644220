.home-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background: inherit;
  /* Assuming the Navbar height is 60px */
  opacity: 0; /* Initial opacity */
  transition: opacity 1s ease-in-out; /* Added fade-in transition */
}

.home-content.show {
  opacity: 1;
  /* Show the content with fade-in effect */
}

.home-content h1 {
  font-size: 6rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fdfdfd;
  position: relative;
  text-align: center;
}

.home-content h1 span {
  display: inline-block;
  padding: 20px 40px;
  border-radius: 40px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.home-content h1 span::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  background-image: url('../bloxewhite.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Adjust the transition property */
  z-index: -1;
}

.home-content h1 span:hover {
  color: transparent;
}

.home-content h1 span:hover::before {
  left: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Adjust the transition property */
}

.home-content h1 span.image-active {
  color: transparent;
}

.home-content h1 span.image-active::before {
  left: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Adjust the transition property */
}

/*... existing styles ...*/


.home-content h1 span:hover {
  color: transparent;
}

.home-content h1 span:hover::before {
  left: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

/* Add this new class */
.home-content h1 span.image-active {
  color: transparent;
}

.home-content h1 span.image-active::before {
  left: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

/*... existing styles ...*/

.password-page {
  background-color: #efe7e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transition: opacity 1s ease-in-out; /* Added fade-out transition */
  opacity: 1; /* Initial opacity */
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: transform 0.8s ease-in-out, opacity 0.5s ease-in-out;
}

.images-container.animate {
  transform: translateX(0);
  opacity: 1;
}

.password-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.password-image:hover {
  transform: scale(1.1) rotate(5deg);
  filter: brightness(1.2);
}

.password-image.clicked {
  transform: scale(1.2) rotate(0);
}

/* Fade-out animation for the password page */
.password-page.hidden {
  opacity: 0;
}
body {
  outline: none; /* Remove the default outline on focus/scroll */
}

