/* Chat.css */
.home-content.chat-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px);
    background: inherit;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    font-family: 'Helvetica', sans-serif; /* Change font to Helvetica */
    color: #333; /* Adjust text color as needed */
    z-index: 2; /* Set a higher value than other elements if needed */
}

.chat-section {
    padding: 20px;
    overflow: auto;
    background-color: transparent; /* Set a transparent background */
    border-radius: 8px; /* Add some border radius for rounded corners */
    text-align: center; /* Center align text */
}

.chat-header h2 {
    margin-bottom: 10px;
    text-transform: uppercase; /* Convert text to all caps */
    color: #efe7e4; /* Set text color to #efe7e4 */
}

.chat-input {
    margin-top: 10px;
}

.chat-input input {
    width: 80%; /* Adjust width as needed */
    padding: 10px; /* Adjust padding as needed */
    text-align: center; /* Center align text */
    border: 1px solid #ccc; /* Thin border */
    border-radius: 4px; /* Slightly rounded corners */
    outline: none; /* Remove the blue outline on focus/click */
}

.home-content.chat-section.visible {
    opacity: 1;
}

/* Add a global style to remove the focus outline */
*:focus {
    outline: none;
}

/* ... other styles ... */
